<section>
    <div class="container-fluid">
        <div class="row h-100 w-100 m-0 login-form">
            <div class="col-4 d-none d-lg-block">
                <div class="row img-login-wrap">
                    <img src="assets/images/login/login3.png" class="p-4" style="width: 30%;" />
                    <p class="img-content">Configure your Classes & Sections</p>
                </div>
                <div>
                    <ul class="stepbystep">
                        <!-- <li class="complete">School Information</li> -->
                        <li class="complete">Classes &amp; Sections</li>
                        <!-- <li class="remaining">Attendance setup - (Student &amp; Employee)</li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md-8 contents">
                <div class="row text-center m-0 p-4">
                    <div class="col-3 school-title">
                        <p class="mt-3">Class & Section</p>
                    </div>
                    <div class="col-6"></div>
                    <div class="col" style="display: flex; justify-content: end; margin-top: 1rem;">
                        <button type="button" [routerLink]="['/class-section']" class="app-btn outline"><i
                                class="fa fa-times"></i>&nbsp;Cancel</button>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 white">
                        <form novalidate [formGroup]="schoolForm">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="title">School Details</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 white">
                                        <div class="row">

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <!-- <select class="form-control" formControlName="defaultCourseSetupId" placeholder="Institute Type" required="required">
                                                            <option value="">Class and Section Details</option>
                                                            <option *ngFor="let classSec of classSectionData" [value]="classSec.defaultCourseSetupId">
                                                                {{classSec.courseName}}</option>
                                                        </select> -->

                                                        <ng-select class="form-control"
                                                            formControlName="defaultCourseSetupId"
                                                            [closeOnSelect]="false" [items]="classSectionData"
                                                            bindLabel="courseName" bindValue="defaultCourseSetupId"
                                                            placeholder="Select Class" [multiple]="true"
                                                            (change)="handleSectionCount()" groupBy="selectedAllGroup"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="false">
                                                            <ng-template ng-optgroup-tmp let-item="item"
                                                                let-item$="item$" let-index="index">
                                                                <input id="item-{{index}}" type="checkbox"
                                                                    [checked]="item$.selected" /> Select All
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                                let-index="index">
                                                                <input id="item-{{index}}" type="checkbox"
                                                                    [checked]="item$.selected" /> {{item.courseName}}
                                                            </ng-template>
                                                            <ng-template ng-multi-label-tmp let-items="items"
                                                                let-clear="clear">
                                                                <div class="ng-value">
                                                                    <ng-container
                                                                        *ngIf="items.length === 1; else moreItems">
                                                                        <div class="ng-value"
                                                                            *ngFor="let item of items">
                                                                            <span
                                                                                class="ng-value-label">{{item.courseName
                                                                                ? item.courseName : item}}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #moreItems>
                                                                        <ng-container
                                                                            *ngIf="items.length === classSectionData.length;else all">
                                                                            <span class="ng-value-label">All
                                                                                selected</span>
                                                                        </ng-container>
                                                                        <ng-template #all>
                                                                            <span
                                                                                class="ng-value-label">{{items.length}}
                                                                                selected</span>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-template>
                                                        </ng-select>

                                                        <label for="classSection">Class <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message
                                                        [control]="schoolForm.controls.defaultCourseSetupId"
                                                        message="Class and Section" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <select class="form-control" formControlName="sectionsCount"
                                                            placeholder="Board of Education" required="required"
                                                            (change)="handleSectionCount()">
                                                            <option value="">No. of Sections per class</option>
                                                            <option *ngFor="let secCount of sectionsPerClassData"
                                                                [value]="secCount.value">{{secCount.text}}</option>
                                                        </select>
                                                        <label for="sectionsPerClass">No. of Sections per class <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.sectionsCount"
                                                        message="No. of Sections per class"
                                                        [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <hr />


                            </div>
                        </form>

                        <ng-container *ngFor="let data of classSectionCreation">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="data.courseName" disabled />
                                                        <label for="courseName">Class</label>
                                                    </span>

                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <select class="form-control" placeholder="Board of Education"
                                                            required="required" [(ngModel)]="data.sectionsCount">
                                                            <option value="" disabled>No of Sections per class</option>
                                                            <option *ngFor="let secCount of sectionsPerClassData"
                                                                [value]="secCount.value">{{secCount.value}}</option>
                                                        </select>
                                                        <label for="sectionsPerClass">No of Sections per class </label>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div>
                            <button type="button" class="app-btn mb-2" style="float: right;" (click)="save()">
                                <i class="fa fa-step-forward mr-1" aria-hidden="true"></i> Save & Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<swal #issueSwal (confirm)="cancel()" (confirmButtonText)="'Login'" title=""
    text="Successfully created your institute. Please login to manage your institution." icon="success">
</swal>