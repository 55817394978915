import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LookupCategory } from "models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LookupService, MessageService } from "projects/common/src/lib";

@Component({
    templateUrl: "./class-section-creation.component.html",
    styleUrls: ["class-section-creation.component.scss"],
})
export class ClassSectionCreationComponent {
    schoolForm: FormGroup;
    lookupCategory: any = LookupCategory;
    classSectionData: any[] = [];
    sectionsPerClassData: any[] = [];
    isFormSubmitted: boolean;
    instituteType: String = '';
    classSectionCreation = [];

    constructor(
        private lookupService: LookupService,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private router: Router,
        private messageService: MessageService,
        private toastr: ToastrService) {
        this.createForm();
        this.getSectionsPerClass();
    }

    ngOnInit() {
        this.getinstituteType();
    }


    private getinstituteType() {
        this.lookupService.getinstituteType().subscribe(result => {
            this.instituteType = result.instituteType;
            this.getClassSection();
        })
    }

    private getClassSection() {
        this.lookupService.defaultClassroom(this.instituteType).subscribe(result => {
            if (result) {
                this.classSectionData = result;
                this.selectAllForDropdownItems(this.classSectionData);
            }
        })
    }

    private getSectionsPerClass() {

        this.sectionsPerClassData = [];
        for (var i = 0; i < 7; i++) {
            this.sectionsPerClassData.push({
                value: (i + 1),
                text: (i + 1)
            })
        }

    }

    private createForm() {
        this.schoolForm = this.formBuilder.group({
            defaultCourseSetupId: ["", Validators.required],
            sectionsCount: ["", Validators.required],
        });
    }

    handleSectionCount() {
        const formData = this.schoolForm.value;

        this.classSectionCreation = [];

        if (this.schoolForm.invalid) {
            return;
        }

        formData.defaultCourseSetupId.forEach(e => {
            this.classSectionCreation.push({
                defaultCourseSetupId: e,
                courseName: this.classSectionData.find(f => f.defaultCourseSetupId == e).courseName,
                sectionsCount: formData.sectionsCount
            })
        })
    }

    save() {
        this.isFormSubmitted = true;

        if (this.schoolForm.invalid) {
            return;
        }
        this.spinner.show();


        this.lookupService.createDefaultCourses(this.classSectionCreation).subscribe((x: any) => {
            //this.spinner.hide();
            
            this.router.navigate(['/class-section']);
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            if (err.status === 400) {
                this.messageService.error(400, 'There is something wrong', 'Error');
            }
        });
    }

    cancel() {
        this.router.navigate(['/dashboard']);
    }

    selectAllForDropdownItems(items: any[]) {
        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }
}